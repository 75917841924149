.image-section {
  background-image: url("../../../img/FONDO.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 500px;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Ajusta los valores según tus preferencias */
}

/* Animaciones */
.animated-service {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-service.active {
  opacity: 1;
  transform: scale(1);
}

/* Botón de Whatsapp */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-button a {
  display: block;
  background-color: #25d366;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.whatsapp-button a:hover {
  background-color: #128C7E;
}

.whatsapp-button img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}